<!--设置管理模块--警报管理页--警报通知设置-->
<template>
  <div id="notification">
    <!-- 头部查询栏 -->
    <div class="header">
      <a-form-model :model="form">
        <div class="form_row">
          <div class="form_col">
            <a-form-model-item>
              <a-cascader :options="options" :placeholder="$t('notification.a1')" @change="onChange" @popupVisibleChange="getBaseInfo"/>
            </a-form-model-item>
          </div>                    
          <div class="form_col">
            <a-form-model-item>
              <a-radio-group v-model="form.alarmType" @change="onAlarmTypeChange">
                <a-radio :value="1">
                  {{$t('notification.a2')}}
                </a-radio>
                <a-radio :value="2">
                  {{$t('notification.a3')}}
                </a-radio>
              </a-radio-group>
              <a-button type="primary" @click="inquire" style="width:120px">{{$t('notification.a4')}}</a-button>
            </a-form-model-item>
          </div>
        </div>
      </a-form-model>
    </div>
    <!-- 表格区域 -->
    <div class="main" v-table-size="tableSize">
        <a-table 
          :columns="columns" 
          :dataSource="data" 
          :pagination="pagination"
          :scroll="size" > 
          <span slot="receive" slot-scope="record">
            <a-radio-group v-model="record.receive" @change="onRadioChangeRed">
              <a-radio-button value="1" style="margin-right: 10px">
                {{$t('notification.a5')}}
              </a-radio-button>
              <a-radio-button value="0" style="margin-right: 10px">
                {{$t('notification.a6')}}
              </a-radio-button>            
            </a-radio-group>            
          </span>
          <span slot="control" slot-scope="record">
            <a-radio-group v-model="record.control" @change="onRadioChangeCtrl">
              <a-radio-button value="1" style="margin-right: 10px">
                {{$t('notification.a5')}}
              </a-radio-button>
              <a-radio-button value="0" style="margin-right: 10px">
                {{$t('notification.a6')}}
              </a-radio-button>            
            </a-radio-group>            
          </span>
        </a-table>
    </div>
    <!-- 底部操作功能 -->
    <div class="footer">
      <a-popconfirm
          :title="$t('notification.a7')"
          :ok-text="$t('notification.a8')"
          :cancel-text="$t('notification.a9')"
          @confirm="set"
          @cancel="cancel"
        >
        <a-button type="primary" class="but">{{$t('notification.a10')}}</a-button>
      </a-popconfirm>   
      <a-popconfirm
          :title="$t('notification.a11')"
          :ok-text="$t('notification.a8')"
          :cancel-text="$t('notification.a9')"
          @confirm="inquire"
          @cancel="cancel"
        >
        <a-button type="primary" class="but">{{$t('notification.a9')}}</a-button>
      </a-popconfirm>           
    </div>   
  </div>
</template>

<script>
import tabletitle from "../../../../components/Title/tabletitle";
import headerTitle from "../../../../components/Title/headerTitleA";
import {getMonitoringAlarmInfo,setMonitoringAlarmInfo} from "../../../../api/sysmanage";

export default {
  data() {
    return {
      baseInfo: this.$store.getters.oneMenu,
      size:{ y: 600 },
      name: this.$t('notification.a12'),
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      data: [],
      loading: false,      
      clientId: 0,
      siteGrId: 0,
      siteId:0,
      siteServiceTypeID:0,
      operateList:[],
      selectedOptions:{},
      form: {
        siteId: 0,
        alarmType: 1,
      },
      rules: {
        mainUser: [
          { required: true, message: 'Please input mainUser name', trigger: 'blur' },
        ],        
      },
      warningSetting:{},        
      pagination: {
        //分页需求配置
        total: 0, //数据总数
        pageSize: 10, //每页中显示10条数据
        showSizeChanger: true, //是否可以改变pagesize
        pageSizeOptions: ["5", "10", "50", "100"], //每页中显示的数据
        showTotal: (total) => `${total} ${this.$t('notification.a13')}`, //分页中显示总的数据
        showQuickJumper: true, //是否可以快速跳转至某页
        defaultCurrent: 1, //默认当前页面数
        // hideOnSinglePage:true,//只有一页时是否隐藏分页：默认值false
      },
      columns: [
        {
          title: "NO",
          dataIndex: "key",
          width: 80,
          ellipsis: true,  
          // scopedSlots: { customRender: "id" },
        },
        {
          title: this.$t('notification.a14'),
          dataIndex: "alarmId",
          width: 80,
          ellipsis: true,          
        },
        {
          title: this.$t('notification.a15'),
          dataIndex: "alarmMessage",
          width: 100,
          ellipsis: true,          
        },
        {
          title: this.$t('notification.a16'),
          // dataIndex: "receive",
          width: 100,
          ellipsis: true,
          scopedSlots: { customRender: 'receive' },          
        },
        {
          title: this.$t('notification.a17'),
          // dataIndex: "control",
          width: 100,
          ellipsis: true,
          scopedSlots: { customRender: 'control' },
        },               
      ],
    };
  },
  computed: {    
    options() {          
      let siteGrList = [];
      let siteList=[];     
      let options = [];     
      console.log("equipment baseInfo:", this.baseInfo);       
      if(this.baseInfo!=null &&this.baseInfo.length>0){
        for(let i=0;i<this.baseInfo.length;i++){
          let client = {
                    value: this.baseInfo[i].id,
                    label: this.baseInfo[i].clientName,
                    children: [],
                  };
          options.push(client);
          siteGrList = this.baseInfo[i].siteGrList; 
          if(siteGrList!=null&&siteGrList.length>0){                       
            for(let j=0;j<siteGrList.length;j++){
              let siteGr = {
                    value: siteGrList[j].id,
                    label: siteGrList[j].name,
                    children: []
                  };
              client.children.push(siteGr);
              siteList= siteGrList[j].siteList;
              if(siteList!=null&&siteList.length>0){
                for(let k=0;k<siteList.length;k++){
                  let site = {
                    value: siteList[k].siteServiceTypeID+","+siteList[k].id,
                    label: siteList[k].name,
                    children: []
                  };
                  siteGr.children.push(site);
                }
              }
            }            
          }          
        }
      }
      console.log("equipment options:", options);
      return options;
    },
  },  
  methods: {
    // 表格高度计算
    tableSize() {
      const indexDom = document.getElementById("notification");
      if(indexDom){
        const height = indexDom.getElementsByClassName("main")[0].clientHeight;
        const title = indexDom.getElementsByClassName("ant-table-thead")[0].clientHeight;
        const tableHeight = height - title + 32;
        this.size.y = tableHeight;
      }
    },  
    getBaseInfo(value){
      const baseInfo = this.baseInfo
      if(value){
        if(baseInfo.length == 0){
          this.baseInfo = this.$store.getters.oneMenu
        }
      }
    },
    onRadioChangeCtrl(e){
      console.log("controlCenterDeterrence data:", e);
    },
    onRadioChangeRed(e) {
      console.log("receiveAlarmDeterrence data:", e);
    },
    onAlarmTypeChange(e) {
      console.log('radio checked', e.target.value);
    },  
    cancel(){
    },
    set() {
      this.setMonitoringAlarm();
    },
    
    onChange(value,selectedOptions) {
      console.log("value:",value);
      console.log("selectedOptions",selectedOptions);
      if(selectedOptions!=null){
        this.selectedOptions=selectedOptions;
        this.warningSetting.customerName = this.selectedOptions[0].label;
        this.warningSetting.builName=this.selectedOptions[2].label;
      }
      if(value!=null){
        this.clientId = value[0];
        this.siteGrId = value[1];
        let arr = value[2].split(',');
        this.siteServiceTypeID = arr[0]-0;
        this.siteId = arr[1]-0;
      }      
    },    
    // 设置方法
    setMonitoringAlarm() {
      console.log("notification data:", this.data);      
      this.loading = true;

      //将绑定控件值置为有效值
      if(this.data==null||this.data.length==0){
        this.$message.error(this.$t('notification.a18')); 
        return;
      }

      for (let i = 0; i < this.data.length; i++) {
        this.data[i].receiveAlarmDeterrence = this.data[i].receive=="1"?true:false;
        this.data[i].controlCenterDeterrence = this.data[i].control=="1"?true:false;
      }

      let data = {
        siteId: this.siteId,
        monitoringAlarmInfos: this.data,
      };
      setMonitoringAlarmInfo(data)
        .then((res) => {
          this.loading = false;
          let { data } = res;
          console.log("setMonitoringAlarmInfo result", data);
          this.$message.info(this.$t('notification.a19')); 
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(this.$t('notification.a20')); 
        }); 
    },    
    // 条件查询方法
    inquire() {
      console.log("notification siteId:", this.siteId);      
      this.loading = true;
      let data = {
        siteId: this.siteId,
        alarmType: this.form.alarmType,
      };
      getMonitoringAlarmInfo(data)
        .then((res) => {
          this.loading = false;
          let { data } = res;
          console.log("notification data", data);
          for (let i = 0; i < data.length; i++) {
            data[i].key = i + 1;
            //将有效值转化为绑定控件值参数值
            data[i].receive = data[i].receiveAlarmDeterrence?"1":"0";
            data[i].control = data[i].controlCenterDeterrence?"1":"0";
          }
          this.data = data;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },    
  },
  components: {
    "my-tabletitle": tabletitle,
    "my-headertitle": headerTitle,
  },
};
</script>

<style scoped>
#notification {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 0px 20px;
  position: relative;
  overflow: hidden;
}
.header {
  min-width: 800px;
  height: 55px;
}
.main {
  width: 100%;
  height: calc(100% - 107px);
  overflow: auto;
}
.form_row {
  display: flex;
  align-items: flex-end;
}
.form_col {
  margin-right: 15px;
  min-width: 1px;
  white-space: nowrap;
}
.footer {
  display: flex;
  justify-content: flex-end;
  padding: 10px 0px;
}
.but {
  width: 120px;
  margin-left: 15px;
}
</style>